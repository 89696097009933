import copy from "copy-to-clipboard";
import { Formik } from "formik";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import {
  Form,
  Alert,
  Button,
  Modal,
  OverlayTrigger,
  Popover,
  Spinner,
} from "react-bootstrap";
import * as yup from "yup";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import { AlertCircle, Copy } from "react-feather";
import { useDropzone } from "react-dropzone";

const AssetsBulkUpdateImportButton = (props) => {
  const { customerId } = props;

  const importBodyRef = useRef(null);
  const notify = useContext(NotyfContext);

  const initialState = {
    showModal: false,
    dryRun: true,
    verifyResults: null,
    importResults: null,
    resultsText: null,
    waitingForResult: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "reset":
        return initialState;

      case "show":
        return {
          ...state,
          showModal: true,
          dryRun: true,
        };

      case "postStart":
        return {
          ...state,
          waitingForResult: true,
        };

      case "postSuccess":
        if (state.dryRun) {
          return {
            ...state,
            verifyResults: action.results,
            resultsText: action.results,
            dryRun: false,
          };
        } else {
          return {
            ...state,
            importResults: action.results,
            resultsText: action.results,
            complete: true,
          };
        }

      case "postDone":
        return {
          ...state,
          waitingForResult: false,
        };

      case "postError":
        if (state.dryRun) {
          return {
            ...state,
            verifyResults: null,
            resultsText:
              "There was an error verifying the file: " + action.results,
          };
        } else {
          return {
            ...state,
            importResults: action.results,
            resultsText:
              "There was an error importing the file: " + action.results,
          };
        }

      default:
        throw new { message: "Invalid action type", type: action.type }();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleClick = () => {
    dispatch({
      type: "show",
    });
  };

  const handleClickPrimary = () => {
    if (importBodyRef.current) {
      importBodyRef.current.submit();
    }
  };

  const handleClickCancel = () => {
    dispatch({
      type: "reset",
    });
  };

  const handleModalHide = () => {
    dispatch({
      type: "reset",
    });
  };

  const handleSubmit = (values, actions) => {
    let {
      // setErrors,
      // setStatus,
      setSubmitting,
    } = actions;

    setSubmitting(true);
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const data = new FormData();
    data.append("DryRun", state.dryRun);
    data.append("Csv", values.csv[0]);
    try {
      dispatch({
        type: "postStart",
      });
      api
        .post(`customers/assets/${customerId}/bulkimport`, data, config)
        .then((res) => {
          dispatch({
            type: "postSuccess",
            results: res.data,
          });
        })
        .catch((reason) => {
          setSubmitting(false);
          dispatch({
            type: "postError",
            results: reason.message ?? reason ?? "error",
          });
        })
        .finally(() => {
          setSubmitting(false);
          dispatch({
            type: "postDone",
          });
        });
    } catch (error) {
      const message = error.message || "Something went wrong";
      setSubmitting(false);
      dispatch({
        type: "postError",
        results: message,
      });
    }
  };

  const copyText = () => {
    if (!state.resultsText) return;
    if (copy(state.resultsText)) {
      notify.open({
        type: "success",
        message: "Copied !",
      });
    }
  };

  return (
    <>
      <Button className="ms-2" variant="primary" onClick={handleClick}>
        Import
      </Button>
      <Modal
        size="xl"
        show={state.showModal}
        onHide={handleModalHide}
        backdrop="static"
        keyboard={false}
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>Assets Bulk Import</Modal.Title>
          {state.resultsText && (
            <Button onClick={() => copyText()} className="mx-2">
              <Copy size={14} />
              &nbsp;Copy to Clipboard
            </Button>
          )}
        </Modal.Header>
        <Modal.Body>
          <ImportBody
            ref={importBodyRef}
            showForm={!state.resultsText}
            onSubmit={handleSubmit}
          />
          {state.resultsText && (
            <>
              <div>
                <pre>{state.resultsText}</pre>
              </div>
            </>
          )}
          {/* {bulkResults && (
            <>
              <div>{bulkResultsDescription}</div>
              <div>
                <pre>{bulkResults}</pre>
              </div>
            </>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          {state.waitingForResult && <Spinner />}
          <Button
            onClick={handleClickPrimary}
            variant={"primary"}
            disabled={
              (state.verifyResults && state.importResults) ||
              state.waitingForResult
            }
          >
            {state.dryRun ? "Verify" : "Import"}
          </Button>
          <Button
            onClick={handleClickCancel}
            variant={"secondary"}
            disabled={state.waitingForResult}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ImportBody = forwardRef((props, ref) => {
  const { showForm, onSubmit } = props;
  const formRef = useRef();
  useImperativeHandle(
    ref,
    () => {
      return {
        submit() {
          formRef.current.submitForm();
        },
      };
    },
    []
  );
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "text/csv": [".csv"],
      "application/vnd.ms-excel": [],
      "application/csv": [],
      "text/x-csv": [],
      "application/x-csv": [],
      "text/comma-separated-values": [],
      "text/x-comma-separated-values": [],
    },
  });
  const files = acceptedFiles.map((file) => file);
  useEffect(() => {
    formRef.current.setFieldValue("csv", files);
  }, [files]);

  const popover = (
    <Popover id="popover-basic" style={{ maxWidth: 960 }}>
      <Popover.Header as="h3">Information</Popover.Header>
      <Popover.Body>
        <pre>
          _id,primary_name,secondary_name,reference,legacy_id,category,supp_string_1,supp_string_2,supp_string_3,supp_string_4,supp_string_5,hash_strings
          <br />
          E.g.
          <br />
          12f3cc4cc16f4c66c47322ab,updated_primary_name,,updated_reference,,,,,,,,hash1#hash2
          <br />
          ,new_primary_name,,my_reference,,my_category,,,,,,hash2#hash3#new_item
        </pre>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={{
          csv: null,
        }}
        validationSchema={yup
          .object()
          // .shape({
          //   // customer: yup.string()
          //   //     .max(255)
          //   //     .required("Customer is required"),
          // })
          .when((values, schema) => {
            return schema.shape({
              csv: yup.mixed().test("fileSize", "File is required", (file) => {
                if (file && file.length > 0) {
                  // setButtonEnabled(true);
                  return true;
                } else {
                  return false;
                }
              }),
            });
          })}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }) => (
          <>
            {showForm && (
              <Form onSubmit={handleSubmit}>
                {errors.submit && (
                  <Alert className="my-3" variant="danger">
                    <div className="alert-message">{errors.submit}</div>
                  </Alert>
                )}

                <Form.Group>
                  <Form.Label>
                    Format
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      placement="right"
                      overlay={popover}
                      delay={{ show: 200, hide: 5000 }}
                    >
                      <AlertCircle className="text-primary ms-2" size={"16"} />
                    </OverlayTrigger>
                  </Form.Label>
                  <div {...getRootProps({ className: "dropzone form-upload" })}>
                    {values.csv && values.csv.length > 0 && (
                      <aside>{values.csv[0].name}</aside>
                    )}
                    <input name="csv" {...getInputProps()} />
                    {(!values.csv || values.csv.length === 0) && (
                      <>
                        <p>Drop a file here, or click to select a file</p>
                        <em>(Only .csv will be accepted)</em>
                      </>
                    )}
                  </div>
                  {errors.csv && touched.csv ? (
                    <div
                      style={{
                        paddingTop: 5,
                        color: "#d9534f",
                        fontSize: ".75rem",
                      }}
                    >
                      {errors.csv}
                    </div>
                  ) : null}
                </Form.Group>
              </Form>
            )}
          </>
        )}
      </Formik>
    </>
  );
});

// export ImportBody;
export default AssetsBulkUpdateImportButton;
