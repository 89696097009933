import React, { useCallback, useState } from "react";
import { Modal, Button, ListGroup } from "react-bootstrap";
import { ChangeLogHelper } from "./ChangeLogHelper";

const ChangeLogChanges = (props) => {
  const { changeData } = props;
  const [show, setShow] = useState(false);
  const { getFieldNameForTargetType } = ChangeLogHelper();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const numberOfChanges = changeData.changes.length;

  const getItemDetails = useCallback(
    (changeItem) => {
      const { fieldName, oldValue, newValue } = changeItem;
      const { target, action } = changeData;

      if (
        target === "group" &&
        action === "update" &&
        (fieldName === "emails" ||
          fieldName === "mobiles" ||
          fieldName === "teams_webhooks")
      ) {
        var displayFieldName = (() => {
          switch (fieldName) {
            case "emails":
              return "Email address";
            case "mobiles":
              return "Mobile number";
            case "teams_webhooks":
              return "Teams API";
            default:
              return fieldName;
          }
        })();
        var oldValues =
          oldValue.length > 2
            ? oldValue
                .substring(1, oldValue.length - 1)
                .split(",")
                .map((v) => v.trim())
            : [];
        var newValues =
          newValue.length > 2
            ? newValue
                .substring(1, newValue.length - 1)
                .split(",")
                .map((v) => v.trim())
            : [];

        if (oldValues.length < newValues.length) {
          var addedItems = newValues.filter((v) => {
            return !oldValues.find((vf) => {
              return vf === v;
            });
          });
          return (
            <>
              {`${displayFieldName} `}
              <span className="text-primary">{`${addedItems} `}</span>was added
              to the contact group
            </>
          );
        } else {
          var removedItems = oldValues.filter((v) => {
            return !newValues.find((vf) => {
              return vf === v;
            });
          });
          return (
            <>
              {`${displayFieldName} `}
              <span className="text-primary">{`${removedItems} `}</span>was
              removed from the contact group
            </>
          );
        }
      }

      if (target === "rule" && fieldName === "zone_id") {
        return <>Zone changed</>;
      }

      if (target === "rule" && fieldName === "polygon") {
        return <>Map area changed</>;
      }

      // For assets, people and zones, we don't have complete details for certain field names
      if (
        ["asset", "person", "zone"].includes(target) &&
        [
          "text_info",
          "date_info",
          "quantity_info",
          "currency_info",
          "weblink_info",
          "email_info",
          "phone_info",
          "thresholds",
          "last_position",
          "radius",
          "polygon",
          "point",
        ].includes(fieldName)
      ) {
        return (
          <>
            <span className="text-primary">
              {getFieldNameForTargetType({
                targetType: changeData.target,
                fieldName: fieldName,
              })}
            </span>
            {" changed"}
          </>
        );
      }

      const oldValueLength = oldValue.length ?? 0;
      const newValueLength = newValue.length ?? 0;

      return (
        <>
          <span className="text-primary">
            {getFieldNameForTargetType({
              targetType: target,
              fieldName: fieldName,
            })}
          </span>
          {oldValueLength === 0 && newValueLength === 0 && <>{" no change"}</>}
          {oldValueLength === 0 && newValueLength > 0 && (
            <>
              {" set to "}
              <span className="text-primary">{newValue}</span>
            </>
          )}
          {oldValueLength > 0 && newValueLength === 0 && (
            <>
              {" cleared, value was "}
              <span className="text-primary">{oldValue}</span>
            </>
          )}
          {oldValueLength > 0 && newValueLength > 0 && (
            <>
              {" changed from "}
              <span className="text-primary">{oldValue}</span>
              {" to "}
              <span className="text-primary">{newValue}</span>
            </>
          )}
        </>
      );
    },
    [changeData]
  );

  return (
    <>
      {numberOfChanges > 0 && (
        <>
          <span>{" and made "}</span>
          <Button
            variant="link"
            className="p-0 text-warning"
            onClick={handleShow}
          >
            {numberOfChanges}
            <span>{` change${numberOfChanges > 1 ? "s" : ""}`}</span>
          </Button>

          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Change Log Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ListGroup className="m-1">
                {changeData.changes.map((value, index, array) => {
                  return (
                    <ListGroup.Item className="p-1" key={index}>
                      {getItemDetails(value)}
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default ChangeLogChanges;
