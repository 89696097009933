import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../api";
import { AlertTriangle, Disc } from "react-feather";
import { Helmet } from "react-helmet-async";
import { Button, Container, Form, Nav, Tab } from "react-bootstrap";
import NotyfContext from "../../contexts/NotyfContext";
import NewModal from "./NewModal";
import SelectionTable from "../../components/tables/SelectionTable";
import Schedule from "./Schedule";
import * as queryString from "query-string";
import useAuth from "../../hooks/useAuth";
import PauseMap from "./PauseMap";
import moment from "moment-timezone";
import { getIcon } from "../../utils/staticMethods";
import useSrlHelper from "../../hooks/useSrlHelper";
import ChangeLogTab from "../../components/detail/ChangeLogTab";

const peopleColumn = [
  {
    Header: "Name",
    accessor: "compoundName",
    link: "/people/edit",
    stateId: "id",
    type: "link",
  },
  {
    Header: " ",
    accessor: "",
    disableSortBy: true,
  },
];

const groupColumn = [
  {
    Header: "Name",
    accessor: "groupName",
    link: "/contactgroup/edit",
    stateId: "id",
    type: "link",
  },
  {
    Header: " ",
    accessor: "",
    disableSortBy: true,
  },
];

const alertColumn = [
  {
    Header: "Name",
    accessor: "message",
  },
  {
    Header: "Sent",
    accessor: "dateTime",
    disableSortBy: true,
    Cell: (cell) => (
      <span>{moment(cell.value).format("DD/MM/YYYY HH:mm:ss")}</span>
    ),
  },
];

const resetOption = [
  { value: 10, name: "Automatically re-arm after 10 seconds" },
  { value: 60, name: "Automatically re-arm after 1 min" },
  { value: 120, name: "Automatically re-arm after 2 mins" },
  { value: 300, name: "Automatically re-arm after 5 mins" },
  { value: 600, name: "Automatically re-arm after 10 mins" },
  { value: 1800, name: "Automatically re-arm after 30 mins" },
  { value: 3600, name: "Automatically re-arm after 1 hour" },
];

const RulesEdit = () => {
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [rule, setRule] = useState();
  const notify = useContext(NotyfContext);
  const [modalTitle, setModalTitle] = useState("Edit rule");
  const [modalType, setModalType] = useState(null);
  const [curTab, setTab] = useState("people");
  const [noContactWarning, setNoContactWarning] = useState(false);
  const [zoneName, setZoneName] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();
  const { getAssetColumnsForSelectionTable } = useSrlHelper();

  const [assetColumn] = useState(getAssetColumnsForSelectionTable());

  useEffect(() => {
    if (id && user) {
      getDetail();
    }
  }, [id, user]);

  const getDetail = () => {
    api
      .get(`rules/${id}`)
      .then((res) => {
        if (res.data.type !== "pausetracking") {
          setTab("schedule");
        }
        if (
          res.data.type === "exitzone" ||
          res.data.type === "enterzone" ||
          res.data.type === "zonesupervision"
        ) {
          if (res.data.zoneId) {
            api.get(`zones/${res.data.zoneId}`).then((res1) => {
              setZoneName(res1.data.compoundName);
            });
          } else {
            setZoneName("Any Zone");
          }
        }
        if (res.data.type === "pausetracking") {
          setTab("map");
        }
        setRule(res.data);
      })
      .catch((err) => console.log(err));
  };

  const needUpdate = () => {
    notify.open({
      type: "success",
      message: "Changes Saved",
    });
    getDetail();
  };

  const onUpdate = (updatedRule) => {
    api.put(`rules`, updatedRule).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setRule(res.data);
    });
  };

  const onEditSuccess = (name, rule) => {
    notify.open({
      type: "success",
      message: "Changes Saved",
    });
    if (name === "edit") {
      setRule(rule);
      if (
        rule.type === "exitzone" ||
        rule.type === "enterzone" ||
        rule.type === "zonesupervision"
      ) {
        if (rule.zoneId) {
          api.get(`zones/${rule.zoneId}`).then((res1) => {
            setZoneName(res1.data.compoundName);
          });
        } else {
          setZoneName("Any Zone");
        }
      }
    } else if (name === "delete") {
      navigate("/rules");
    }
  };

  const renderDescription = () => {
    if (!rule) {
      return null;
    }
    if (
      rule.type === "exitzone" ||
      rule.type === "enterzone" ||
      rule.type === "zonesupervision"
    ) {
      return (
        <>
          <Disc size={18} /> {zoneName}
        </>
      );
    } else if (rule.type === "lowbattery") {
      return rule.lowerThreshold + " %";
    } else if (rule.type === "lowvoltage") {
      return rule.lowerThreshold + " mV";
    } else if (rule.type === "speeding") {
      return rule.upperThreshold + " mph";
    } else {
      return null;
    }
  };

  const radioChange = (name) => {
    const obj = JSON.parse(JSON.stringify(rule));
    obj.status = name;
    api.put(`rules`, obj).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setRule(res.data);
    });
  };

  const changeReset = (e) => {
    const obj = JSON.parse(JSON.stringify(rule));
    obj.resetInterval = e.target.value;
    api.put(`rules`, obj).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setRule(res.data);
    });
  };

  const onCheckChanged = (e, value) => {
    const obj = JSON.parse(JSON.stringify(rule));
    obj[value] = e.target.checked;
    api.put(`rules`, obj).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setRule(res.data);
    });
  };

  return (
    <React.Fragment>
      <Container fluid className="p-0">
        <Helmet defer={false} title={`${rule?.ruleName || "Rule"}`} />
        <h3 className="mb-3">
          <span>{getIcon(rule?.type)} </span>
          {rule?.ruleName}
        </h3>
        <div>
          <h3 className="mb-3">{renderDescription()}</h3>
        </div>
        <div className="d-flex">
          {rule && user?.editRules && (
            <Button
              onClick={() => {
                setModalTitle("Edit rule");
                setModalType(rule.type);
              }}
            >
              Edit
            </Button>
          )}
          {rule && user?.deleteRules && (
            <Button
              variant="danger"
              className="ms-1"
              onClick={() => {
                setModalTitle("Please Confirm");
                setModalType(rule.type);
              }}
            >
              Delete
            </Button>
          )}
          {noContactWarning && rule?.type !== "pausetracking" && (
            <Button variant="warning" className="ms-1">
              <AlertTriangle size={14} /> No Contact Group
            </Button>
          )}
        </div>
        <div className="tab mt-3">
          <Tab.Container
            id="left-tabs-example"
            activeKey={curTab}
            onSelect={(e) => setTab(e)}
          >
            <Nav variant="tabs">
              {rule?.type !== "pausetracking" && (
                <Nav.Item>
                  <Nav.Link eventKey="schedule">Schedule</Nav.Link>
                </Nav.Item>
              )}
              {rule?.type === "pausetracking" && (
                <Nav.Item>
                  <Nav.Link eventKey="map">Map</Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="people">People</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="assets">Assets</Nav.Link>
              </Nav.Item>
              {rule?.type !== "pausetracking" && (
                <>
                  <Nav.Item>
                    <Nav.Link eventKey="contacts">Contact </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="alerts">Recent Alerts</Nav.Link>
                  </Nav.Item>
                </>
              )}
              {user?.role === "Admin" && (
                <Nav.Item>
                  <Nav.Link eventKey="change_log">Change Log</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              {rule && (
                <Tab.Pane eventKey="schedule">
                  {user?.editRules && rule.type !== "attendance" && (
                    <>
                      <Form.Check
                        type="radio"
                        label="Trigger at all times"
                        checked={rule.status === "armed" || false}
                        onChange={() => radioChange("armed")}
                      />
                      <Form.Check
                        type="radio"
                        label="Trigger according to the schedule below"
                        checked={rule.status === "timed" || false}
                        onChange={() => radioChange("timed")}
                      />
                      <Form.Check
                        type="radio"
                        label="Disable"
                        className="mb-3"
                        checked={rule.status === "disarmed" || false}
                        onChange={() => radioChange("disarmed")}
                      />
                    </>
                  )}
                  {user?.editRules && rule.type === "attendance" && (
                    <>
                      <Form.Check
                        type="radio"
                        label="Rule active"
                        checked={rule.status === "armed" || false}
                        onChange={() => radioChange("armed")}
                      />
                      <Form.Check
                        type="radio"
                        label="Rule inactive"
                        className="mb-3"
                        checked={rule.status === "disarmed" || false}
                        onChange={() => radioChange("disarmed")}
                      />
                    </>
                  )}
                  {user?.editRules &&
                    (rule.type === "motion" ||
                      rule.type === "zonesupervision") && (
                      <Form.Select
                        defaultValue={rule.resetInterval}
                        className="mb-4"
                        onChange={(e) => changeReset(e)}
                      >
                        {resetOption.map((item) => (
                          <option key={item.value} value={item.value}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    )}
                  <Schedule rule={rule} needUpdate={needUpdate} />
                </Tab.Pane>
              )}
              {user?.editRules && rule?.type === "pausetracking" && (
                <Tab.Pane eventKey="map">
                  <Form.Check
                    type="radio"
                    label="Rule active"
                    checked={rule.status === "armed" || false}
                    onChange={() => radioChange("armed")}
                  />
                  <Form.Check
                    type="radio"
                    label="Rule inactive"
                    className="mb-3"
                    checked={rule.status === "disarmed" || false}
                    onChange={() => radioChange("disarmed")}
                  />
                  <PauseMap rule={rule} onUpdate={onUpdate} />
                </Tab.Pane>
              )}
              <Tab.Pane eventKey="people">
                {user?.editRules && !(user?.role === "User") && (
                  <Form.Check
                    type="switch"
                    label="All People"
                    checked={rule?.allPeople || false}
                    onChange={(e) => onCheckChanged(e, "allPeople")}
                  />
                )}
                {rule?.allPeople && (
                  <>
                    <h4 className="py-3 text-black">
                      Associated with All People
                    </h4>
                  </>
                )}
                {!rule?.allPeople && (
                  <SelectionTable
                    currentTab={curTab}
                    buttonText="Add a Person"
                    apiName="people"
                    title="Associated People"
                    type="rules"
                    filterId=""
                    columns={peopleColumn}
                    id={id}
                    entry={`rules/${id}/people`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="assets">
                {user?.editRules && !(user?.role === "User") && (
                  <Form.Check
                    type="switch"
                    label="All Assets"
                    checked={rule?.allAssets || false}
                    onChange={(e) => onCheckChanged(e, "allAssets")}
                  />
                )}
                {rule?.allAssets && (
                  <>
                    <h4 className="py-3 text-black">
                      Associated with All Assets
                    </h4>
                  </>
                )}
                {!rule?.allAssets && (
                  <SelectionTable
                    currentTab={curTab}
                    buttonText="Add an Asset"
                    apiName="assets"
                    title="Associated Assets"
                    type="rules"
                    filterId=""
                    columns={assetColumn}
                    id={id}
                    entry={`rules/${id}/assets`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="contacts">
                <SelectionTable
                  noDataWarning={(e) => setNoContactWarning(e)}
                  currentTab={curTab}
                  buttonText="Add a Contact Group"
                  apiName="contactgroups"
                  type="rules"
                  filterId=""
                  title="Contact Groups"
                  columns={groupColumn}
                  id={id}
                  entry={`rules/${id}/contactgroups`}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="alerts">
                <SelectionTable
                  currentTab={curTab}
                  apiName="alerts"
                  type="rules"
                  filterId=""
                  title="Recent Alerts"
                  columns={alertColumn}
                  id={id}
                  entry={`rules/${id}/alerts`}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="change_log">
                <ChangeLogTab id={id} type={"rule"} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
      <NewModal
        modalType={modalType}
        setModalType={(v) => setModalType(v)}
        modalTitle={modalTitle}
        rule={rule}
        zoneName={zoneName}
        onSuccess={onEditSuccess}
      />
    </React.Fragment>
  );
};
export default RulesEdit;
