import React, { useCallback, useState } from "react";
import SearchTable from "../../components/tables/SearchTable";
import api from "../../api";
import { Card, Container, Spinner } from "react-bootstrap";
import { Edit } from "react-feather";
import moment from "moment-timezone";
import ChangeLogDetails from "../../pages/changelog/ChangeLogDetails";

const ChangeLogTab = (props) => {
  const { id, type } = props;
  const columns = [];
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const fetchIdRef = React.useRef(0);

  const renderHeader = useCallback(({ column }) => {
    if (column.filterValue) {
      return <div className="w-100 badge-soft-secondary">{column.name}</div>;
    } else {
      return <>{column.name}</>;
    }
  }, []);

  const renderDetails = useCallback(({ column, row, cell, value }) => {
    return (
      <>
        <ChangeLogDetails changeData={row.original} />
      </>
    );
  }, []);

  const fetchData = useCallback(
    (pageIndex, pageSize, sortBy, filters, filter) => {
      (async () => {
        // Give this fetch an ID
        const fetchId = ++fetchIdRef.current;

        try {
          setLoading(true);

          var response = await api.get(`changelogs/${type}/${id}`, {
            params: {
              page: pageIndex + 1,
              limit: pageSize,
              sort: sortBy,
              filter: filter,
            },
          });
          if (fetchId === fetchIdRef.current) {
            const total = response.headers["x-total-count"] ?? 0;
            setCount(total);
            setData(response.data);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    },
    []
  );

  columns.push({
    Header: renderHeader,
    name: "Date and Time",
    accessor: "dateTime",
    disableSortBy: true,
    disableFilters: true,
    width: 150,
    minWidth: 150,
    maxWidth: 150,
    //Filter: TextFilter,
    Cell: ({ column, row, cell, value }) => {
      return <>{moment(cell.value).format("DD/MM/YYYY HH:mm")}</>;
    },
  });

  columns.push({
    Header: renderHeader,
    name: "Change Details",
    accessor: "id",
    disableSortBy: true,
    disableFilters: true,
    width: 800,
    minWidth: 200,
    maxWidth: 800,
    // Filter: TextFilter,
    // minWidth:500,
    Cell: renderDetails,
  });

  return (
    <>
      <Container fluid className="p-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="d-flex align-items-center">
            <h1 className="h3 mb-0">
              <Edit className="me-2" /> Change Log&nbsp;
              {loading && (
                <Spinner
                  animation="border"
                  variant="primary"
                  size="sm"
                  className="p-0 m-0"
                />
              )}
            </h1>
          </div>
        </div>
        <Container fluid className="p-0" style={{ overflow: "auto" }}>
          <Card className="bg-white p-0" style={{ overflow: "auto" }}>
            <SearchTable
              columns={columns}
              data={data}
              fetchData={fetchData}
              exportData={() => {}}
              count={count}
              options={{
                canPickColumns: false,
                canExport: false,
                canFilter: true,
              }}
            />
          </Card>
        </Container>
      </Container>
    </>
  );
};

export default ChangeLogTab;
