import { Card, Col, Container, Row } from "react-bootstrap";
import useTheme from "../../hooks/useTheme";
import loginBg from "../../assets/img/loginbg.mov";
import srlLogo from "../../assets/img/srl/srl-telematics.png";
import srlIcon from "../../assets/img/srl/red-icon.png";

const AuthWrapper = ({ children, title, subTitle }) => {
  const { theme } = useTheme();

  if (theme === "srl") {
    return (
      <>
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <Card className="px-3">
              <div className="d-flex justify-content-center align-items-start">
                <img
                  style={{
                    position: "relative",
                    top: "-60px",
                    width: "120px",
                    height: "120px",
                  }}
                  src={srlIcon}
                  alt=""
                />
              </div>
              <Row>
                <Col sm={{ span: 8, offset: 2 }}>
                  <div className="d-flex justify-content-center align-items-start">
                    <img className="w-100" src={srlLogo} alt="" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="px-5">
                  <hr className="border-bottom border-3 border-dark" />
                </Col>
              </Row>
              <div className="text-center mt-4">
                <h2 className="h2 dark-text">{title}</h2>
                <p className="dark-text">{subTitle}</p>
              </div>
              <Card className="p-3 auth-card">{children}</Card>
            </Card>
            <div>&nbsp;</div>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          <Col sm={{ span: 8, offset: 2 }}>
            <Container className="d-flex flex-column p-3">
              <video
                className="position-fixed min-vw-100 min-vh-100 video-background"
                autoPlay
                muted
                loop
                id="myVideo"
              >
                <source src={loginBg} type="video/mp4" />
              </video>
              <div className="text-center mt-4">
                <h2 className="h2 white-text">{title}</h2>
                <p className="lead white-text">{subTitle}</p>
              </div>
              <Card className="p-3">{children}</Card>
            </Container>
          </Col>
        </Row>
      </>
    );
  }
};

export default AuthWrapper;
