import { useCallback } from "react";
import useAuth from "../../hooks/useAuth";
import useSrlHelper from "../../hooks/useSrlHelper";

export const ChangeLogHelper = () => {
  const { user } = useAuth();
  const { isSrl } = useSrlHelper();
  const fieldNames = {
    asset: {
      primary_name: "Primary Name",
      secondary_name: "Secondary Name",
      reference: "Reference",
      icon: "Icon",
      text_info: "Text Info Items",
      date_info: "Date Info Items",
      quantity_info: "Quantity Info Items",
      currency_info: "Currency Info Items",
      weblink_info: "Weblink Info Items",
      email_info: "Email Info Items",
      phone_info: "Phone Info Items",
      thresholds: "Threashold",
      last_position: "Last Position",
      radius: "Geofence Radius",
      polygon: "Geofence Polygon",
      point: "Position",
    },
    person: {
      primary_name: "Primary Name",
      secondary_name: "Secondary Name",
      reference: "Reference",
      icon: "Icon",
      text_info: "Text Info Items",
      date_info: "Date Info Items",
      quantity_info: "Quantity Info Items",
      currency_info: "Currency Info Items",
      weblink_info: "Weblink Info Items",
      email_info: "Email Info Items",
      phone_info: "Phone Info Items",
      thresholds: "Threashold",
      screen_alerts: "Screen Alerts",
      play_sounds: "Play Sounds",
      last_position: "Last Position",
      radius: "Geofence Radius",
      polygon: "Geofence Polygon",
      point: "Position",
    },
    zone: {
      primary_name: "Primary Name",
      secondary_name: "Secondary Name",
      reference: "Reference",
      icon: "Icon",
      text_info: "Text Info Items",
      date_info: "Date Info Items",
      quantity_info: "Quantity Info Items",
      currency_info: "Currency Info Items",
      weblink_info: "Weblink Info Items",
      email_info: "Email Info Items",
      phone_info: "Phone Info Items",
      thresholds: "Threashold",
      last_position: "Last Position",
      radius: "Geofence Radius",
      polygon: "Geofence Polygon",
      point: "Position",
    },
    tracker: {
      "": "",
    },
    reader: {
      "": "",
    },
    tag: {
      "": "",
    },
    group: {
      groupname: "Contact Group Name",
      emails: "Email Address",
      mobiles: "Mobile Number",
      teams_webhooks: "Teams URL",
    },
    rule: {
      rulename: "Rule Name",
      zone_id: "Zone",
      status: "Status",
      all_assets: "All Assets",
      all_people: "All People",
      all_zones: "All Zones",
      asset_ids: "Persons List",
      person_ids: "People List",
      zone_ids: "Zones List",
      contact_ids: "Contacts",
      alarm_name: "Alarm Name",
      upper_threshold: "Upper Threshold",
      lower_threshold: "Lower Threshold",
      // all_trackers: "All Trackers",
      // all_readers: "All Readers",
      // all_tags: "All Tags",
    },
    permission: {
      permission_name: "Access Profile Name",
      all_assets: "View all Assets",
      all_people: "View all People",
      all_zones: "View all Zones",
      all_rules: "View all Rules",
      all_trackers: "View all Trackers",
      all_readers: "View all Readers",
      all_tags: "View all Tags",
      all_contacts: "View all Contact Groups",
      view_notes: "View Notes",
      schedule_jobs: "Schedule Tasks",
      log_jobs: "Log Tasks",
      all_jobs: "Users can only view tasks assigned to them",
      create_assets: "Create Assets",
      edit_assets: "Edit Assets",
      delete_assets: "Delete Assets",
      create_people: "Create People",
      edit_people: "Edit People",
      delete_people: "Delete People",
      create_zones: "Create Zones",
      edit_zones: "Edit Zones",
      delete_zones: "Delete Zones",
      create_rules: "Create Rules",
      edit_rules: "Edit Rules",
      delete_rules: "Delete Rules",
      create_trackers: "Create Trackers",
      edit_trackers: "Edit Trackers",
      delete_trackers: "Delete Trackers",
      create_tags: "Create Tags",
      edit_tags: "Edit Tags",
      delete_tags: "Delete Tags",
      create_readers: "Create Readers",
      edit_readers: "Edit Readers",
      delete_readers: "Delete Readers",
      create_notes: "Create Notes",
      edit_notes: "Edit Notes",
      delete_notes: "Delete Notes",
      create_contacts: "Create Contacts",
      edit_contacts: "Edit Contacts",
      delete_contacts: "Delete Contacts",
    },
  };

  const getVerbAndPrepositionForAction = useCallback((action) => {
    switch (action) {
      case "create":
        return {
          verb: "created",
          preposition: "",
        };

      case "update":
        return {
          verb: "updated",
          preposition: "",
        };

      case "delete":
        return {
          verb: "deleted",
          preposition: "",
        };

      case "link":
        return {
          verb: "linked",
          preposition: "to",
        };

      case "unlink":
        return {
          verb: "unlinked",
          preposition: "from",
        };

      default:
        return {
          verb: "",
          preposition: "",
        };
    }
  }, []);

  const getValuesForTargetType = useCallback((targetType, changeData) => {
    switch (targetType) {
      case "asset":
        return {
          id: changeData.elementId,
          name: changeData.primaryName,
          route: `/assets/edit?id=${changeData.elementId}&direct=details`,
        };
      case "person":
        return {
          id: changeData.elementId,
          name: changeData.primaryName,
          route: `/people/edit?id=${changeData.elementId}&direct=details`,
        };
      case "zone":
        return {
          id: changeData.elementId,
          name: changeData.primaryName,
          route: `/zones/edit?id=${changeData.elementId}&direct=details`,
        };
      case "tracker":
        return {
          id: changeData.deviceId,
          name:
            changeData.serial +
            (changeData.deviceName !== null
              ? ` (${changeData.deviceName})`
              : ""),
          route: `/trackers?filter=${changeData.serial}`,
        };

      case "reader":
        return {
          parentId: changeData.deviceId,
          name:
            changeData.serial +
            (changeData.deviceName !== null
              ? ` (${changeData.deviceName})`
              : ""),
          route: `/readers?filter=${changeData.serial}`,
        };

      case "tag":
        return {
          parentId: changeData.deviceId,
          name:
            changeData.serial +
            (changeData.deviceName !== null
              ? ` (${changeData.deviceName})`
              : ""),
          route: `/tags?filter=${changeData.serial}`,
        };

      case "app":
        return {
          parentId: changeData.deviceId,
          name:
            changeData.serial +
            (changeData.deviceName !== null
              ? ` (${changeData.deviceName})`
              : ""),
          route: `/apps?filter=${changeData.serial}`,
        };

      case "qrcode":
        return {
          parentId: changeData.deviceId,
          name:
            changeData.serial +
            (changeData.deviceName !== null
              ? ` (${changeData.deviceName})`
              : ""),
          route: `/qrcodes?filter=${changeData.serial}`,
        };

      case "group":
        return {
          parentId: changeData.contactGroupId,
          name: changeData.groupName,
          route: `/contactgroups/edit?id=${changeData.contactGroupId}`,
        };

      case "rule":
        return {
          parentId: changeData.ruleId,
          name: changeData.ruleName,
          route: `/rules/edit?id=${changeData.contactGroupId}`,
        };

      case "user":
        return {
          id: changeData.elementId,
          name: changeData.primaryName,
          route: `/people/edit?id=${changeData.elementId}&direct=details`,
        };

      default:
        return {
          id: null,
          name: null,
          route: null,
        };
    }
  }, []);

  const actionDetails = useCallback((changeData) => {
    var parentId = null;
    var parentName = null;
    var parentRoute = null;
    var childId = null;
    var childName = null;
    var childRoute = null;

    ({
      id: parentId,
      name: parentName,
      route: parentRoute,
    } = getValuesForTargetType(changeData.target, changeData));
    ({
      id: childId,
      name: childName,
      route: childRoute,
    } = getValuesForTargetType(
      changeData.target !== changeData.childType ? changeData.childType : "",
      changeData
    ));

    return {
      parentId,
      parentName,
      parentRoute,
      childId,
      childName,
      childRoute,
    };
  }, []);

  const getFieldNameForTargetType = useCallback(
    (props) => {
      const { targetType, fieldName } = props;

      var returnValue;

      if (targetType === "asset" && isSrl()) {
        const srlFieldNames = {
          supp_string_1: "Asset Owner",
          legacy_id: "SRL Fleet #",
          supp_string_2: "Customer Fleet #",
          category: "SRL Product Name",
          primary_name: "Customer Reference",
        };
        returnValue = srlFieldNames[fieldName];
      } else if (targetType === "asset" && user.assetFieldConfig.enabled) {
        returnValue = user.assetFieldConfig.customFields.find((v, i, a) => {
          return v.fieldName === fieldName;
        })?.displayName;
      } else if (targetType === "person" && user.personFieldConfig.enabled) {
        returnValue = user.personFieldConfig.customFields.find((v, i, a) => {
          return v.fieldName === fieldName;
        })?.displayName;
      } else if (targetType === "zone" && user.zoneFieldConfig.enabled) {
        returnValue = user.zoneFieldConfig.customFields.find((v, i, a) => {
          return v.fieldName === fieldName;
        })?.displayName;
      }

      returnValue =
        returnValue ??
        fieldNames[targetType][fieldName] ??
        `[${targetType} ${fieldName}]`;

      return returnValue;
    },
    [user]
  );

  return {
    getVerbAndPrepositionForAction,
    actionDetails,
    getFieldNameForTargetType,
  };
};

/*
{
    "categories": [
        "12",
        "34",
        "56",
        "78",
        "90"
    ],
    "customFields": [
        {
            "fieldName": "primary_name",
            "displayName": "Firstname",
            "enabled": true,
            "mandatory": true
        },
        {
            "fieldName": "secondary_name",
            "displayName": "Surname",
            "enabled": true,
            "mandatory": true
        },
        {
            "fieldName": "reference",
            "displayName": "Reference",
            "enabled": false,
            "mandatory": false
        },
        {
            "fieldName": "category",
            "displayName": "Department",
            "enabled": true,
            "mandatory": true
        },
        {
            "fieldName": "legacy_id",
            "displayName": "NI Number",
            "enabled": true,
            "mandatory": true
        },
        {
            "fieldName": "supp_string_1",
            "displayName": "Tax Ref",
            "enabled": true,
            "mandatory": false
        },
        {
            "fieldName": "supp_string_2",
            "displayName": "Supplementary 2",
            "enabled": false,
            "mandatory": false
        },
        {
            "fieldName": "supp_string_3",
            "displayName": "Supplementary 3",
            "enabled": false,
            "mandatory": false
        },
        {
            "fieldName": "supp_string_4",
            "displayName": "Supplementary 4",
            "enabled": false,
            "mandatory": false
        },
        {
            "fieldName": "supp_string_5",
            "displayName": "Supplementary 5",
            "enabled": false,
            "mandatory": false
        }
    ],
    "showAdditionalInfo": true,
    "showThreshold": false,
    "enabled": true
}
*/
