import React from "react";
import api from "../../api";
import ExportButton from "../../components/ExportButton";
import { downloadFile } from "../../utils/staticMethods";
import AssetsBulkUpdateImportButton from "./AssetsBulkUpdateImportButton";

const AssetsBulkUpdate = (props) => {
  const { customerId } = props;

  const exportFile = () => {
    api
      .get(`customers/assets/${customerId}/bulkexport`, {
        responseType: "blob",
      })
      .then((res) => downloadFile(res))
      .catch((reason) => {
        console.log("Failed to export", { reason });
      });
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-4">
        <ExportButton title={"Export"} count={1} onClick={() => exportFile()} />
        <AssetsBulkUpdateImportButton customerId={customerId} />
      </div>
    </>
  );
};

export default AssetsBulkUpdate;
