import React from "react";

// All widgetPages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Access Profiles
import AccessProfiles from "./pages/accessprofiles/AccessProfiles";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Dashboards
import Default from "./pages/dashboards";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";

// UI components
import Alerts from "./pages/alerts/Alerts";

// Protected routes
import Icons from "./pages/icons/AssetIcon/Icons";
import Attendance from "./components/report/Attendance";
import AccessProfilesEdit from "./pages/accessprofiles/AccessProfilesEdit";
import HashTags from "./pages/hastags/Hashtags";
import People from "./pages/people/People";
import Zones from "./pages/zones/Zones";
import Trackers from "./pages/trackers/Trackers";
import Readers from "./pages/readers/Readers";
import Tags from "./pages/tags/Tags";
import ContactGroups from "./pages/contactgroups/ContactGroups";
import Rules from "./pages/rules/Rules";
import ZonesEdit from "./pages/zones/ZonesEdit";
import PeopleEdit from "./pages/people/PeopleEdit";
import Create from "./pages/rules/Create";
import RulesEdit from "./pages/rules/RulesEdit";
import Positions from "./components/report/Positions";
import ContactEdit from "./pages/contactgroups/ContactEdit";
import PasswordReset from "./pages/auth/PasswordReset";
import GlobalSearch from "./pages/search/GlobalSearch";
import Customers from "./pages/customers/Customers";
import CustomerEdit from "./pages/customers/CustomerEdit";
import DeviceProfile from "./pages/deviceProfile/DeviceProfile";
import DeviceEdit from "./pages/deviceProfile/DeviceEdit";
import ReaderEdit from "./pages/readers/ReaderEdit";
import TagEdit from "./pages/tags/TagEdit";
import TrackerEdit from "./pages/trackers/TrackerEdit";
import RealLocation from "./components/detail/RealLocation";
import MapDashboard from "./pages/maps/MapDashboard";
import CreateWizard from "./pages/rules/CreateWizard";
import TaskTypes from "./pages/task/TaskTypes";
import TaskScheduled from "./pages/task/TaskScheduled";
import CompletedTask from "./pages/task/CompletedTask";
import OutstandingTask from "./pages/task/OutlandingTask";
import Floorplans from "./pages/floorplans/Floorplans";
import FloorPlanView from "./pages/floorplans/FloorPlanView";
import ReaderView from "./pages/readers/ReaderView";
import PlanBy from "./components/planby";
import FloorplanForm from "./pages/floorplans/FloorplanForm";
import CagesByCustomerPage from "./pages/widgetPages/CagesByCustomerPage";
import CagesByCustomerWithAllocationPage from "./pages/widgetPages/CagesByCustomerWithAllocationPage";
import CagesInTransitPage from "./pages/widgetPages/CagesInTransitPage";
import CagesByDepotPage from "./pages/widgetPages/CagesByDepotPage";
import CratesByCustomerPage from "./pages/widgetPages/CratesByCustomerPage";
import CratesByCustomerWithAllocationPage from "./pages/widgetPages/CratesByCustomerWithAllocationPage";
import CratesInTransitPage from "./pages/widgetPages/CratesInTransitPage";
import CratesByDepotPage from "./pages/widgetPages/CratesByDepotPage";
import Chargers from "./pages/chargers/Chargers";
import ChargerEdit from "./pages/chargers/ChargerEdit";
import ImageEdit from "./components/imageEdit/ImageEdit";
import AssetsNew from "./pages/assets/AssetsNew";
import BatteryLevelPage from "./pages/widgetPages/BatteryLevelPage";
import BatteryExchangePage from "./pages/widgetPages/BatteryExchangePage";
import DeploymentPage from "./pages/widgetPages/DeploymentPage";
import BatteryVoltagePage from "./pages/widgetPages/BatteryVoltagePage";
import DeploymentsPage from "./pages/widgetPages/DeploymentsPage";
import DeviceLinkerPage from "./pages/widgetPages/DeviceLinkerPage";
import OffNetwork from "./pages/widgetPages/OffNetworkPage";
import CratesOffNetworkPage from "./pages/widgetPages/CratesOffNetworkPage";
import AttendanceHistory from "./components/report/AttendanceHistory";
import UserReaderMap from "./pages/readers/UserReaderMap";
import TagAssign from "./pages/widgetPages/TagAssign";
import TagUnassign from "./pages/widgetPages/TagUnassign";
import MultiAssetMapPage from "./pages/widgetPages/MultiAssetMapPage";
import Licence from "./pages/auth/Licence";
import Apps from "./pages/apps/Apps";
import AppEdit from "./pages/apps/AppEdit";
import ArchivedTrackers from "./pages/trackers/ArchivedTrackers";
import ArchivedReaders from "./pages/readers/ArchivedReaders";
import ArchivedTags from "./pages/tags/ArchivedTags";
import FavouritePageWrapper from "./wrappers/FavouritePageWrapper";
import AssetsWrapper from "./wrappers/AssetsWrapper";
import ArchivedAssetsWrapper from "./wrappers/ArchivedAssetsWrapper";
import AssetsEditWrapper from "./wrappers/AssetsEditWrapper";
import ArchivedAssetsEditWrapper from "./wrappers/ArchivedAssetsEditWrapper";
import QrCodes from "./pages/qrcode/QrCodes";
import QrCodesEdit from "./pages/qrcode/QrCodesEdit";
import QrCodesScanResult from "./pages/qrcode/QrCodesScanResult";
import PermissionDenied from "./pages/auth/PermissionDenied";
import AssetsSearchWrapper from "./wrappers/AssetsSearchWrapper";
import FloorplansView from "./pages/floorplans/FloorplansView";
import Redirector from "./components/Redirector";
import CagesInTransitVehiclePage from "./pages/widgetPages/CagesInTransitVehiclePage";
import CagesByBranchPage from "./pages/widgetPages/CagesByBranchPage";
import TotesInTransitVehiclePage from "./pages/widgetPages/TotesInTransitVehiclePage";
import TotesByDepotPage from "./pages/widgetPages/TotesByDepotPage";
import TotesByBranchPage from "./pages/widgetPages/TotesByBranchPage";
import TotesOffNetworkPage from "./pages/widgetPages/TotesOffNetworkPage";
import TokenGuard from "./components/guards/TokenGuard";
import ChangeLog from "./pages/changelog/ChangeLog";
import PositionsBeta from "./components/report/PositionsBeta";

// routers, different roles has their own router
const routes = [
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin", "User"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    exact: true,
    children: [
      {
        path: "scheduledtask",
        element: <TaskScheduled type={"showTasks"} />,
      },
      {
        path: "completedtask",
        element: <CompletedTask type={"showTasks"} />,
      },
      {
        path: "outstandingtask",
        element: <OutstandingTask type={"showTasks"} />,
      },
      {
        path: "",
        element: <Default />,
      },
      {
        path: "favourite",
        element: <FavouritePageWrapper />,
      },
      {
        path: "device/linker",
        element: <DeviceLinkerPage />,
      },
      {
        path: "batteryLevel",
        element: <BatteryLevelPage />,
      },
      {
        path: "offNetwork",
        element: <OffNetwork />,
      },
      {
        path: "cratesOffNetwork",
        element: <CratesOffNetworkPage />,
      },
      {
        path: "batteryExchanges",
        element: <BatteryExchangePage />,
      },
      {
        path: "deployment",
        element: <DeploymentPage />,
      },
      {
        path: "deployments",
        element: <DeploymentsPage />,
      },
      {
        path: "batteryVoltage",
        element: <BatteryVoltagePage />,
      },
      {
        path: "maps",
        element: <MapDashboard type={"showMap"} />,
      },
      {
        path: "contactgroups",
        element: <ContactGroups />,
      },
      {
        path: "rules",
        element: <Rules />,
      },
      {
        path: "rules/create",
        element: <Create />,
      },
      {
        path: "rules/wizard",
        element: <CreateWizard />,
      },
      {
        path: "contactgroups/edit",
        element: <ContactEdit />,
      },
      {
        path: "tags/assign",
        element: <TagAssign />,
      },
      {
        path: "tags/unassign",
        element: <TagUnassign />,
      },
      {
        path: "maps/multiAsset",
        element: <MultiAssetMapPage></MultiAssetMapPage>,
      },
      {
        path: "floorplansview",
        element: <FloorplansView type={"showFloorPlans"} />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin", "User", "Root"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "assets",
        element: <AssetsWrapper />,
      },
      {
        path: "assetsnew",
        element: <AssetsNew />,
      },
      {
        path: "assets/search",
        element: <AssetsSearchWrapper />,
      },
      {
        path: "archived-assets",
        element: <ArchivedAssetsWrapper />,
      },
      {
        path: "people",
        element: <People />,
      },
      {
        path: "zones",
        element: <Zones />,
      },
      {
        path: "floorplans",
        element: <Floorplans type={"showFloorPlans"} />,
      },
      {
        path: "floorplanview",
        element: <FloorPlanView type={"showFloorPlans"} />,
      },
      {
        path: "trackers",
        element: <Trackers />,
      },
      {
        path: "archived-trackers",
        element: <ArchivedTrackers />,
      },
      {
        path: "readers",
        element: <Readers />,
      },
      {
        path: "archived-readers",
        element: <ArchivedReaders />,
      },
      {
        path: "tags",
        element: <Tags />,
      },
      {
        path: "archived-tags",
        element: <ArchivedTags />,
      },
      {
        path: "apps",
        element: <Apps />,
      },
      {
        path: "qrcodes",
        element: <QrCodes />,
      },
      {
        path: "solarchargers",
        element: <Chargers />,
      },
      {
        path: "assets/edit",
        element: <AssetsEditWrapper />,
      },
      {
        path: "archived-assets/edit",
        element: <ArchivedAssetsEditWrapper />,
      },
      {
        path: "zones/edit",
        element: <ZonesEdit />,
      },
      {
        path: "qrcodes/edit",
        element: <QrCodesEdit />,
      },
      {
        path: "/cagesByCustomer",
        element: <CagesByCustomerPage />,
      },
      {
        path: "/cagesByCustomerWithAllocation",
        element: <CagesByCustomerWithAllocationPage />,
      },
      {
        path: "/cagesInTransit",
        element: <CagesInTransitPage />,
      },
      {
        path: "/cagesByDepot",
        element: <CagesByDepotPage />,
      },
      {
        path: "/cratesByCustomer",
        element: <CratesByCustomerPage />,
      },
      {
        path: "/cratesByCustomerWithAllocation",
        element: <CratesByCustomerWithAllocationPage />,
      },
      {
        path: "/cratesInTransit",
        element: <CratesInTransitPage />,
      },
      {
        path: "/cratesByDepot",
        element: <CratesByDepotPage />,
      },
      {
        path: "/cagesInTransitVehicle",
        element: <CagesInTransitVehiclePage />,
      },
      {
        path: "/cagesByBranch",
        element: <CagesByBranchPage />,
      },
      {
        path: "/totesInTransitVehicle",
        element: <TotesInTransitVehiclePage />,
      },
      {
        path: "/totesByDepot",
        element: <TotesByDepotPage />,
      },
      {
        path: "/totesByBranch",
        element: <TotesByBranchPage />,
      },
      {
        path: "/totesOffNetwork",
        element: <TotesOffNetworkPage />,
      },
      {
        path: "people/edit",
        element: <PeopleEdit />,
      },
      {
        path: "rules/edit",
        element: <RulesEdit />,
      },
      {
        path: "globalsearch",
        element: <GlobalSearch />,
      },
      {
        path: "scanresult/:serial",
        element: <QrCodesScanResult />,
      },
      {
        path: "pictureediting",
        element: <ImageEdit />,
      },
      {
        path: "permissiondenied",
        element: <PermissionDenied />,
      },
      {
        path: "redirector",
        element: <Redirector />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "readers/map",
        element: <UserReaderMap />,
      },
      {
        path: "hashtags",
        element: <HashTags />,
      },
      {
        path: "alerthistory",
        element: <Alerts />,
      },
      {
        path: "tasktypes",
        element: <TaskTypes type={"showTasks"} />,
      },
      {
        path: "changelog",
        element: <ChangeLog />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Root"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "customers",
        element: <Customers />,
      },
      {
        path: "readers/flooplan",
        element: <ReaderView />,
      },
      {
        path: "reallocate",
        element: <RealLocation />,
      },
      {
        path: "customers/edit",
        element: <CustomerEdit />,
      },
      {
        path: "deviceProfiles",
        element: <DeviceProfile />,
      },
      {
        path: "deviceProfiles/edit",
        element: <DeviceEdit />,
      },
      {
        path: "readers/edit",
        element: <ReaderEdit />,
      },
      {
        path: "archived-readers/edit",
        element: <ReaderEdit />,
      },
      {
        path: "tags/edit",
        element: <TagEdit />,
      },
      {
        path: "archived-tags/edit",
        element: <TagEdit />,
      },
      {
        path: "apps/edit",
        element: <AppEdit />,
      },
      {
        path: "solarchargers/edit",
        element: <ChargerEdit />,
      },
      {
        path: "trackers/edit",
        element: <TrackerEdit />,
      },
      {
        path: "archived-trackers/edit",
        element: <TrackerEdit />,
      },
      {
        path: "floorplan/edit",
        element: <FloorplanForm />,
      },
    ],
  },
  {
    path: "report",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "attendance",
        element: <Attendance />,
      },
      {
        path: "positions",
        element: <Positions />,
      },
      {
        path: "positions-beta",
        element: <PositionsBeta />,
      },
      {
        path: "attendancehist",
        element: <AttendanceHistory mode="hist" />,
      },
      {
        path: "attendancelive",
        element: <AttendanceHistory mode="live" />,
      },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard role={["Admin"]}>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "planby",
        element: <PlanBy />,
      },
      {
        path: "accessprofiles",
        element: <AccessProfiles />,
      },
      {
        path: "accessprofiles/edit",
        element: <AccessProfilesEdit />,
      },
    ],
  },
  {
    path: "account",
    element: (
      <TokenGuard>
        <AuthLayout />
      </TokenGuard>
    ),
    children: [
      {
        path: "register",
        element: <SignUp />,
      },
      {
        path: "resetpassword",
        element: <PasswordReset />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "licence",
        element: <Licence />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "icons",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "asset-icon",
        element: <Icons />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
