import React from "react";
import { Button, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";

const AccessProfileElementAddToExistingModal = (props) => {
  const { show, onHide, onSuccess, elementId } = props;

  const columns = [
    {
      Header: "Profile Name",
      accessor: "permissionName",
      type: "link",
      link: "/accessprofiles/edit",
      stateId: "id",
    },
    {
      Header: "",
      accessor: "id",
      Cell: (cell) => (
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            variant={"success"}
            onClick={() => {
              onSuccess(cell.row.original);
            }}
          >
            Add
          </Button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  return (
    <>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Existing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTable
            apiName="accessprofiles"
            columns={columns}
            parentId={`!${elementId}`}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccessProfileElementAddToExistingModal;
