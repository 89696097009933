import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as queryString from "query-string";
import _ from "lodash";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import useAuth from "../../hooks/useAuth";

const TokenGuard = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = queryString.parse(location.search);
  const notify = React.useContext(NotyfContext);
  const { setAccountTokenEmail } = useAuth();

  React.useEffect(async () => {
    if (!_.isString(token)) {
      notify.open({
        type: "error",
        message: "Missing token",
      });
      navigate("/auth/sign-in");
    } else {
      try {
        var response = await api.put(
          `accounts/validatetoken?token=${encodeURIComponent(token)}`
        );
        if (!_.isString(response.data) || response.data.length === 0) {
          notify.open({
            type: "error",
            message: "Unable to verify token",
          });
          navigate("/auth/sign-in");
        }

        setAccountTokenEmail(response.data);
      } catch (error) {
        navigate("/auth/sign-in");
      }
    }
  }, [token]);

  return <>{children}</>;
};

export default TokenGuard;
