import React from "react";
import { useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { store } from "./redux/store";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
import { AuthProvider } from "./contexts/JWTContext";
import moment from "moment-timezone";
import { globalConfig } from "./config";

// import Settings from "./components/Settings";

// set and store a current time that wont be update by component update
window.curTime = moment();

const App = () => {
  const content = useRoutes(routes);

  return (
    <HelmetProvider>
      {globalConfig.applicationTheme === "srl" ? (
        <>
          <Helmet prioritizeSeoTags>
            <title>
              SRL Telematics | Remote Asset Monitoring Software | SRL Traffic
              Systems
            </title>
            <meta
              name="application-name"
              content={globalConfig.productName.toUpperCase()}
            />
            <meta
              name="description"
              content="SRL Telematics offers a proactive asset monitoring solution that reduces site visits by tracking data in real time from your SRL assets."
            />
            <link rel="icon" href="/favicon-srl.jpg" type="image/jpg" />
          </Helmet>
        </>
      ) : (
        <>
          <Helmet prioritizeSeoTags>
            <title>
              {globalConfig.productName.toUpperCase().concat(" Sign In")}
            </title>
            <meta
              name="application-name"
              content={globalConfig.productName.toUpperCase()}
            />
            <meta
              name="description"
              content="TracLogik's asset tracking and management solutions are underpinned by the TracLogik software suite. Developed in house, in the UK, the TracLogik platform can be flexed to support an unlimted number of asset tracking use cases and applications."
            />
            <link rel="icon" href="/favicon.jpg" type="image/jpg" />
          </Helmet>
        </>
      )}
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              {/* <Settings /> */}
              <AuthProvider>{content}</AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
