import React, { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import CommonTable from "../../components/tables/CommonTable";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";

const AddExistingModal = (props) => {
  const { show, onHide, apiName, dataId, onUpdatedRules } = props;

  const notify = useContext(NotyfContext);

  const columns = [
    {
      Header: "Name",
      accessor: "ruleName",
      type: "link",
      link: "/rules/edit",
      stateId: "id",
    },
    {
      Header: "Type",
      accessor: "type",
      type: "rule",
    },
    {
      Header: "Status",
      accessor: "status",
      type: "ruleStatus",
    },
    {
      Header: "",
      accessor: "id",
      Cell: (cell) => (
        <div className="d-flex justify-content-end">
          <Button
            className="me-2"
            variant={"success"}
            onClick={() => {
              handleClick(cell.row.original.id ?? -1);
            }}
          >
            Add
          </Button>
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const handleClick = (id) => {
    api.post(`rules/${id}/${apiName}/link/${dataId}`).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      onUpdatedRules();
    });
  };

  return (
    <React.Fragment>
      <Modal size="lg" show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Add to Existing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CommonTable
            apiName="rules"
            columns={columns}
            parentId={`!${dataId}`}
          />
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
export default AddExistingModal;
