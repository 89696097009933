import React, { createElement, useEffect, useMemo, useState } from "react";
// import { ChangeLogHelper } from "./ChangeLogHelper";
import ChangeLogChanges from "./ChangeLogChanges";
import { Link } from "react-router-dom";
import useSrlHelper from "../../hooks/useSrlHelper";

// const toFirstLetterUpperCase = (str) => {
//   return str.slice(0, 1).toUpperCase() + str.slice(1, str.length);
// };

const DisplayUser = (props) => {
  const { changeData } = props;
  const [model, setModel] = useState({});

  useEffect(() => {
    setModel({
      ...model,
      linkParams: new URLSearchParams([
        ...Object.entries({ id: changeData.userId, direct: "details" }),
      ]).toString(),
      email: changeData.email,
    });
  }, [changeData]);

  return <Link to={`/people/edit?${model.linkParams}`}>{model.email}</Link>;
};

const DisplayItem = (props) => {
  const { isSrl } = useSrlHelper();
  const { changeData, mode = "parent" } = props;

  const [targetType, setTargetType] = useState();
  const [target, setTarget] = useState();

  const [displayFragment, setDisplayFragment] = useState(<></>);

  useEffect(() => {
    const targetType =
      mode === "parent" ? changeData.target : changeData.childType;
    setTarget(targets.find((value) => value.target === targetType));
    setTargetType(targetType);
  }, [changeData, mode]);

  useEffect(() => {
    if (!target) {
      setDisplayFragment(
        <>
          <div>{`${targetType} (${mode}) not found`}</div>
        </>
      );
    } else {
      var itemName = undefined;
      var itemId = changeData.elementId;
      switch (targetType) {
        case "asset":
          itemName = isSrl()
            ? changeData.legacyId === "OEM"
              ? changeData.suppString2 || changeData.legacyId
              : changeData.legacyId || "?"
            : (
                (changeData.primaryName || "?") +
                " " +
                (changeData.secondaryName || "")
              ).trim();
          break;

        case "person":
        case "zone":
          itemName = (
            (changeData.primaryName || "?") +
            " " +
            (changeData.secondaryName || "")
          ).trim();
          break;

        case "tracker":
        case "reader":
        case "tag":
        case "app":
        case "qrcode":
        case "solarcharger":
          itemId = changeData.deviceId;
          itemName = changeData.serial ?? "#";
          break;

        case "group":
          itemId = changeData.contactGroupId;
          itemName = changeData.groupName;
          break;

        case "rule":
          itemId = changeData.ruleId;
          itemName = changeData.ruleName;
          break;

        case "permission":
          itemId = changeData.permissionId;
          itemName = changeData.permissionName;
          break;

        default:
          break;
      }

      // If the action is delete, we don't want to display a link
      if (changeData.action === "delete") {
        setDisplayFragment(<>{`${target.displayName} ${itemName}`}</>);
      } else {
        switch (targetType) {
          case "tracker":
          case "reader":
          case "tag":
          case "app":
          case "qrcode":
          case "solarcharger":
            {
              const linkParams = new URLSearchParams([
                ...Object.entries({ filter: itemName }),
              ]).toString();

              setDisplayFragment(
                <>
                  {`${target.displayName} `}
                  <Link to={`/${target.apiName}?${linkParams}`}>
                    {itemName}
                  </Link>
                </>
              );
            }
            break;

          default:
            {
              const linkParams = new URLSearchParams([
                ...Object.entries({ id: itemId, direct: "details" }),
              ]).toString();

              setDisplayFragment(
                <>
                  {`${target.displayName} `}
                  <Link to={`/${target.apiName}/edit?${linkParams}`}>
                    {itemName}
                  </Link>
                </>
              );
            }
            break;
        }
      }
    }
  }, [targetType, target]);

  return <>{displayFragment}</>;
};

const DisplayLinkCount = (props) => {
  const { changeData } = props;

  switch (changeData.childType) {
    case "user":
    case "asset":
    case "assets":
    case "person":
    case "people":
    case "zone":
    case "zones":
    case "tracker":
    case "trackers":
    case "reader":
    case "readers":
    case "tag":
    case "tags":
    case "app":
    case "apps":
      return <>{`${changeData.changeCount} ${changeData.childType}`}</>;

    case "qrcode":
      return <>{`${changeData.changeCount} QR code`}</>;

    case "qrcodes":
      return <>{`${changeData.changeCount} QR codes`}</>;

    case "solarcharger":
      return <>{`${changeData.changeCount} solar charger`}</>;

    case "solarchargers":
      return <>{`${changeData.changeCount} solar chargers`}</>;

    case "group":
      return <>{`${changeData.changeCount} contact group`}</>;

    case "groups":
      return <>{`${changeData.changeCount} contact groups`}</>;

    case "rule":
    case "rules":
      return <>{`${changeData.changeCount} ${changeData.childType}`}</>;

    case "permission":
      return <>{`${changeData.changeCount} access profile`}</>;

    case "permissions":
      return <>{`${changeData.changeCount} access profiles`}</>;

    default:
      return <>{`${changeData.changeCount} ? ${changeData.childType} ?`}</>;
  }
};

const DisplayIt = (props) => {
  const { changeData } = props;
  return (
    <div>
      <DisplayUser {...props} /> {changeData.target} {changeData.action}{" "}
      <ChangeLogChanges {...props} />
      <span>
        <b>{" <<<"}</b>
      </span>
    </div>
  );
};

const DisplayTargetAsset = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "create":
      return (
        <>
          <DisplayUser {...props} />
          {" created "}
          <DisplayItem {...props} />
        </>
      );

    case "update":
      return (
        <>
          <DisplayUser {...props} />
          {" updated "}
          <DisplayItem {...props} />
          <ChangeLogChanges {...props} />
        </>
      );

    case "delete":
      return (
        <>
          <DisplayUser {...props} />
          {" deleted "}
          <DisplayItem {...props} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetPerson = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "create":
      return (
        <>
          <DisplayUser {...props} />
          {" added "}
          <DisplayItem {...props} />
        </>
      );

    case "update":
      return (
        <>
          <DisplayUser {...props} />
          {" updated "}
          <DisplayItem {...props} />
          <ChangeLogChanges {...props} />
        </>
      );

    case "delete":
      return (
        <>
          <DisplayUser {...props} />
          {" deleted "}
          <DisplayItem {...props} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetZone = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "create":
      return (
        <>
          <DisplayUser {...props} />
          {" created "}
          <DisplayItem {...props} />
        </>
      );

    case "update":
      return (
        <>
          <DisplayUser {...props} />
          {" updated "}
          <DisplayItem {...props} />
          <ChangeLogChanges {...props} />
        </>
      );

    case "delete":
      return (
        <>
          <DisplayUser {...props} />
          {" deleted "}
          <DisplayItem {...props} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetTracker = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayItem {...props} />
          {" to "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayItem {...props} />
          {" from "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetReader = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayItem {...props} />
          {" to "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayItem {...props} />
          {" from "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetTag = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayItem {...props} />
          {" to "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayItem {...props} />
          {" from "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetApp = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayItem {...props} />
          {" to "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayItem {...props} />
          {" from "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetQrcode = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayItem {...props} />
          {" to "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayItem {...props} />
          {" from "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetSolarCharger = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayItem {...props} />
          {" to "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayItem {...props} />
          {" from "}
          <DisplayItem {...props} mode={"child"} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetGroup = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "create":
      return (
        <>
          <DisplayUser {...props} />
          {" created "}
          <DisplayItem {...props} />
        </>
      );

    case "update":
      return (
        <>
          <DisplayUser {...props} />
          {" updated "}
          <DisplayItem {...props} />
          <ChangeLogChanges {...props} />
        </>
      );

    case "delete":
      return (
        <>
          <DisplayUser {...props} />
          {" deleted "}
          <DisplayItem {...props} />
        </>
      );

    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayLinkCount {...props} />
          {" to "}
          <DisplayItem {...props} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayLinkCount {...props} />
          {" from "}
          <DisplayItem {...props} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetRule = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "create":
      return (
        <>
          <DisplayUser {...props} />
          {" created "}
          <DisplayItem {...props} />
        </>
      );

    case "update":
      return (
        <>
          <DisplayUser {...props} />
          {" updated "}
          <DisplayItem {...props} />
          <ChangeLogChanges {...props} />
        </>
      );

    case "delete":
      return (
        <>
          <DisplayUser {...props} />
          {" deleted "}
          <DisplayItem {...props} />
        </>
      );

    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayLinkCount {...props} />
          {" to "}
          <DisplayItem {...props} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayLinkCount {...props} />
          {" from "}
          <DisplayItem {...props} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const DisplayTargetPermission = (props) => {
  const { changeData } = props;
  switch (changeData.action) {
    case "create":
      return (
        <>
          <DisplayUser {...props} />
          {" created "}
          <DisplayItem {...props} />
        </>
      );

    case "update":
      return (
        <>
          <DisplayUser {...props} />
          {" updated "}
          <DisplayItem {...props} />
          <ChangeLogChanges {...props} />
        </>
      );

    case "delete":
      return (
        <>
          <DisplayUser {...props} />
          {" deleted "}
          <DisplayItem {...props} />
        </>
      );

    case "link":
      return (
        <>
          <DisplayUser {...props} />
          {" linked "}
          <DisplayLinkCount {...props} />
          {" to "}
          <DisplayItem {...props} />
        </>
      );

    case "unlink":
      return (
        <>
          <DisplayUser {...props} />
          {" unlinked "}
          <DisplayLinkCount {...props} />
          {" from "}
          <DisplayItem {...props} />
        </>
      );

    default:
      return (
        <>
          <DisplayIt {...props}></DisplayIt>
        </>
      );
  }
};

const targets = [
  {
    target: "asset",
    component: DisplayTargetAsset,
    apiName: "assets",
    displayName: "asset",
  },
  {
    target: "person",
    component: DisplayTargetPerson,
    apiName: "people",
    displayName: "person",
  },
  {
    target: "zone",
    component: DisplayTargetZone,
    apiName: "zones",
    displayName: "zone",
  },
  {
    target: "tracker",
    component: DisplayTargetTracker,
    apiName: "trackers",
    displayName: "tracker",
  },
  {
    target: "reader",
    component: DisplayTargetReader,
    apiName: "readers",
    displayName: "reader",
  },
  {
    target: "tag",
    component: DisplayTargetTag,
    apiName: "tags",
    displayName: "tag",
  },
  {
    target: "app",
    component: DisplayTargetApp,
    apiName: "apps",
    displayName: "app",
  },
  {
    target: "qrcode",
    component: DisplayTargetQrcode,
    apiName: "qrcodes",
    displayName: "QR code",
  },
  {
    target: "solarcharger",
    component: DisplayTargetSolarCharger,
    apiName: "solarchargers",
    displayName: "solar charger",
  },
  {
    target: "group",
    component: DisplayTargetGroup,
    apiName: "contactgroups",
    displayName: "contact group",
  },
  {
    target: "rule",
    component: DisplayTargetRule,
    apiName: "rules",
    displayName: "rule",
  },
  {
    target: "permission",
    component: DisplayTargetPermission,
    apiName: "accessprofiles",
    displayName: "access profile",
  },
];

const ChangeLogDetails = (props) => {
  const { changeData } = props;
  const changeDataTarget = changeData.target;
  const [displayComponent, setDisplayComponent] = useState(<></>);
  const target = useMemo(() => {
    return targets.find((value) => value.target === changeDataTarget);
  }, [changeDataTarget]);

  useEffect(() => {
    if (target === undefined) {
      setDisplayComponent(<>{`Unknown change log [${changeData.target}]`}</>);
    } else {
      setDisplayComponent(createElement(target.component, props));
    }
  }, [target]);

  return displayComponent;
};

export default ChangeLogDetails;
