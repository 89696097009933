import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, Spinner } from "react-bootstrap";
import "../../assets/scss/3-components/_signIn.scss";

import useAuth from "../../hooks/useAuth";
import "../../assets/scss/3-components/_video.scss";
import * as queryString from "query-string";
import api from "../../api";
import NotyfContext from "../../contexts/NotyfContext";
import AuthWrapper from "../../components/auth/AuthWrapper";
import { globalConfig } from "../../config";

const PasswordReset = () => {
  const navigate = useNavigate();
  const { isAuthenticated, accountTokenEmail } = useAuth();
  const location = useLocation();
  const { token } = queryString.parse(location.search);
  const notify = useContext(NotyfContext);

  if (!token) {
    navigate("/");
  }

  useEffect(() => {
    if (isAuthenticated) navigate("/");
  }, [isAuthenticated]);

  return (
    <React.Fragment>
      <Helmet
        defer={false}
        title={globalConfig.productName.toUpperCase().concat(" Password reset")}
      />
      <AuthWrapper title={"Reset Your Password"} subTitle={""}>
        <Formik
          initialValues={{
            submit: false,
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().max(255).required("Password is required"),
            confirmPassword: Yup.string().when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
              ),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            let data = {
              password: values.password,
              token: token,
            };
            try {
              api
                .put(
                  `accounts/passwordreset?password=${encodeURIComponent(
                    data.password
                  )}&token=${encodeURIComponent(data.token)}`,
                  data
                )
                .then(() => {
                  notify.open({
                    type: "success",
                    message: "Changes Saved",
                  });
                  setStatus({ success: true });
                  setErrors({});
                  setSubmitting(false);
                  navigate("/");
                })
                .catch((error) => {
                  let message = error.message || "Something went wrong";
                  if (error.message && error.message.indexOf("400") > -1) {
                    message = "Invalid Credentials.";
                  }
                  setStatus({ success: false });
                  setErrors({ submit: message });
                  setSubmitting(false);
                });
            } catch (error) {
              let message = error.message || "Something went wrong";
              if (error.message && error.message.indexOf("400") > -1) {
                message = "Invalid Credentials.";
              }
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <Form onSubmit={handleSubmit}>
              {errors.submit && (
                <Alert className="my-3" variant="danger">
                  <div className="alert-message">{errors.submit}</div>
                </Alert>
              )}
              <Form.Control
                hidden
                size="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
                autoComplete="username email"
                defaultValue={accountTokenEmail}
              />
              <Form.Group className="mb-3">
                <Form.Label className="text-gray">New Password</Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="password"
                  placeholder="Enter your password"
                  autoComplete="new-password"
                  value={values.password}
                  isInvalid={Boolean(touched.password && errors.password)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.password && (
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label className="text-gray">
                  Confirm New Password
                </Form.Label>
                <Form.Control
                  size="lg"
                  type="password"
                  name="confirmPassword"
                  placeholder="Enter your password again"
                  autoComplete="new-password"
                  value={values.confirmPassword}
                  isInvalid={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {!!touched.confirmPassword && (
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <div className="d-flex justify-content-between align-items-center cursor-pointer mt-3">
                <div>
                  <Button
                    type="submit"
                    variant="primary"
                    size="lg"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                  {isSubmitting && <Spinner animation="border" />}
                </div>
                <div>
                  <Button
                    type="button"
                    variant="secondary"
                    size="lg"
                    disabled={isSubmitting}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </AuthWrapper>
    </React.Fragment>
  );
};

export default PasswordReset;
