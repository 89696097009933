import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../api";
import { Helmet } from "react-helmet-async";
import { Button, Container, Form, Modal, Nav, Tab } from "react-bootstrap";
import SelectionTable from "../../components/tables/SelectionTable";
import NotyfContext from "../../contexts/NotyfContext";
import { ReactComponent as IconUser } from "./../../assets/img/svgs/screen.svg";
import { ReactComponent as IconAssets } from "./../../assets/img/svgs/maze.svg";
import { ReactComponent as IconPeople } from "./../../assets/img/svgs/people.svg";
import { ReactComponent as IconZones } from "./../../assets/img/svgs/zone.svg";
import { ReactComponent as IconRules } from "./../../assets/img/svgs/warning.svg";
import { ReactComponent as IconTrackers } from "./../../assets/img/svgs/earth.svg";
import { ReactComponent as IconReaders } from "./../../assets/img/svgs/wifi.svg";
import { ReactComponent as IconTags } from "./../../assets/img/svgs/tags.svg";
import { ReactComponent as IconNote } from "./../../assets/img/svgs/note.svg";
import { ReactComponent as IconContact } from "./../../assets/img/svgs/contact.svg";
import * as queryString from "query-string";
import useAuth from "../../hooks/useAuth";
import { Bookmark } from "react-feather";
import WidgetTabs from "../customers/WidgetTabs";
import { setSession, setUserSession } from "../../utils/jwt";
import UserLogin from "../../components/navbar/UserLogin";
import useSrlHelper from "../../hooks/useSrlHelper";
import ChangeLogTab from "../../components/detail/ChangeLogTab";

// 'User' role access control
const AccessProfilesEdit = () => {
  const notify = useContext(NotyfContext);
  const location = useLocation();
  const { id } = queryString.parse(location.search);
  const [result, setResult] = useState(null);
  const [showModal, setShow] = useState(false);
  const [curTab, setTab] = useState("users");
  const [curName, setName] = useState("");
  const [userCount, setUserCount] = useState(0);
  const [actionName, setAction] = useState("");
  const { user, getProfile } = useAuth();
  const { getAssetColumnsForSelectionTable } = useSrlHelper();

  const [assetColumn] = useState(getAssetColumnsForSelectionTable());

  const ruleColumn = [
    {
      Header: "Name",
      accessor: "ruleName",
      type: "link",
      link: "/rules/edit",
      stateId: "id",
    },
    {
      Header: "Type",
      accessor: "type",
      type: "rule",
    },
    {
      Header: " ",
      accessor: "",
      disableSortBy: true,
    },
  ];

  const groupColumn = [
    {
      Header: "Name",
      accessor: "groupName",
      type: "link",
      link: "/contactgroups/edit",
      stateId: "id",
    },
    {
      Header: " ",
      accessor: "",
      disableSortBy: true,
    },
  ];

  const trackerColumn = [
    {
      Header: "Serial#",
      accessor: "serial",
      type: "first",
    },
    {
      Header: " ",
      accessor: "",
      disableSortBy: true,
    },
  ];

  const userCol = [
    {
      Header: "Name",
      accessor: "compoundName",
      type: "link",
      hardLink: "/people/edit",
      stateId: "id",
    },
    {
      Header: "Login",
      accessor: "id",
      disableSortBy: true,
      Cell: (cell) => {
        return (
          <span
            onClick={() => onOperate(cell.row.original.id)}
            className="text-primary cursor-pointer"
          >
            Login
          </span>
        );
      },
    },
    {
      Header: " ",
      accessor: "",
      disableSortBy: true,
    },
  ];

  const onOperate = (id) => {
    api.post(`accounts/accessprofilelogin`, { id }).then((res) => {
      let token = res.data?.accessToken;
      localStorage.removeItem("recentItem");
      let token1 = localStorage.getItem("accessToken");
      setUserSession(token1);
      setSession(token);
      getProfile();
    });
  };

  useEffect(() => {
    if (!id) return;
    getDetail();
  }, [id]);

  const getDetail = () => {
    api.get(`accessprofiles/${id}`).then((res) => {
      setResult(res.data);
      setName(res.data.permissionName);
    });
  };

  const onTextChange = (e) => {
    setName(e.target.value);
  };

  const onTabChange = (e) => {
    setTab(e);
  };

  const editName = () => {
    const obj = JSON.parse(JSON.stringify(result));
    obj.permissionName = curName;
    api.put(`accessprofiles`, obj).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setResult(res.data);
      setName(res.data.permissionName);
    });
    setShow(false);
  };

  const deleteOne = () => {
    api.delete(`accessprofiles/${id}`).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      window.history.back();
    });
    setShow(false);
  };

  const onCheckChanged = (e, value) => {
    const obj = JSON.parse(JSON.stringify(result));
    obj[value] = e.target.checked;
    api.put(`accessprofiles`, obj).then((res) => {
      notify.open({
        type: "success",
        message: "Changes Saved",
      });
      setResult(res.data);
      setName(res.data.permissionName);
    });
  };
  return (
    <React.Fragment>
      <Helmet
        defer={false}
        title={result?.permissionName || "Access Profile"}
      />
      <Modal show={showModal} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {actionName === "Edit" ? "Edit Access Profile" : "Please Confirm"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {actionName === "Edit" && (
            <input value={curName} onChange={(e) => onTextChange(e)} />
          )}
          {actionName === "Delete" && (
            <div>
              <div>
                Are you sure that you wish to permanently delete this Access
                Profile?
              </div>
              <div>Deletions are not reversible.</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {actionName === "Edit" && (
            <Button variant="primary" onClick={() => editName()}>
              Edit
            </Button>
          )}
          {actionName === "Delete" && (
            <Button variant="danger" onClick={() => deleteOne()}>
              Delete
            </Button>
          )}
          <Button variant={"secondary"} onClick={() => setShow(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{result?.permissionName}</h1>
        <div className="">
          <Button
            variant="primary"
            onClick={() => {
              setAction("Edit");
              setShow(true);
            }}
          >
            Edit Name
          </Button>
          <Button
            variant="danger"
            className="ms-2"
            onClick={() => {
              setAction("Delete");
              setShow(true);
            }}
          >
            Delete
          </Button>
          <UserLogin id={id} homePage />
        </div>
        <div className="tab mt-4">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="users"
            onSelect={(e) => onTabChange(e)}
          >
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="users">Users</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="assets">Assets</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="people">People</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="zones">Zones</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="rules">Rules</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="trackers">Trackers</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="readers">Readers</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="tags">Tags</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="groups">Contact Groups</Nav.Link>
              </Nav.Item>
              {user?.showTasks && (
                <Nav.Item>
                  <Nav.Link eventKey="tasks">Tasks</Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Nav.Link eventKey="notes">Notes</Nav.Link>
              </Nav.Item>
              {user?.showPermissionTiles && (
                <Nav.Item>
                  <Nav.Link eventKey="tiles">Home Tiles</Nav.Link>
                </Nav.Item>
              )}
              {user?.showPermissionAssetTiles && (
                <Nav.Item>
                  <Nav.Link eventKey="assetsTiles">Asset Tiles</Nav.Link>
                </Nav.Item>
              )}
              {user?.role === "Admin" && (
                <Nav.Item>
                  <Nav.Link eventKey="change_log">Change Log</Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="users">
                <h4>
                  <svg width="18" height="18">
                    <IconUser width="18" height="18" />
                  </svg>
                  <span className="ms-1">Users ({userCount})</span>
                </h4>
                <SelectionTable
                  onReturnTotalCount={setUserCount}
                  currentTab={curTab}
                  buttonText="Add a User"
                  title="Users"
                  columns={userCol}
                  id={id}
                  filterId={""}
                  selectionStr={"users"}
                  apiNew={`accessprofiles/${id}/availableusers`}
                  apiName={`users`}
                  entry={`accessprofiles/${id}/users`}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="assets">
                <h4>
                  <svg width="18" height="18">
                    <IconAssets width="18" height="18" />
                  </svg>
                  <span className="ms-1">Assets</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      label="Create Assets"
                      checked={result?.createAssets || false}
                      onChange={(e) => onCheckChanged(e, "createAssets")}
                    />
                    <Form.Check
                      type="switch"
                      label="Edit Assets"
                      checked={result?.editAssets || false}
                      onChange={(e) => onCheckChanged(e, "editAssets")}
                    />
                    <Form.Check
                      type="switch"
                      label="Delete Assets"
                      checked={result?.deleteAssets || false}
                      onChange={(e) => onCheckChanged(e, "deleteAssets")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  label="View all Assets"
                  checked={result?.allAssets || false}
                  onChange={(e) => onCheckChanged(e, "allAssets")}
                />
                {!result?.allAssets && (
                  <SelectionTable
                    filterId=""
                    currentTab={curTab}
                    buttonText="Add an Asset"
                    apiName="assets"
                    title="Assets"
                    columns={assetColumn}
                    id={id}
                    entry={`accessprofiles/${id}/assets`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="people">
                <h4>
                  <svg width="18" height="18">
                    <IconPeople width="18" height="18" />
                  </svg>
                  <span className="ms-1">People</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      label="Create People"
                      checked={result?.createPeople || false}
                      onChange={(e) => onCheckChanged(e, "createPeople")}
                    />
                    <Form.Check
                      type="switch"
                      label="Edit People"
                      checked={result?.editPeople || false}
                      onChange={(e) => onCheckChanged(e, "editPeople")}
                    />
                    <Form.Check
                      type="switch"
                      label="Delete People"
                      checked={result?.deletePeople || false}
                      onChange={(e) => onCheckChanged(e, "deletePeople")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  label="View all People"
                  checked={result?.allPeople || false}
                  onChange={(e) => onCheckChanged(e, "allPeople")}
                />
                {!result?.allPeople && (
                  <SelectionTable
                    filterId=""
                    currentTab={curTab}
                    buttonText="Add a Person"
                    apiName="people"
                    title="People"
                    columns={assetColumn}
                    id={id}
                    entry={`accessprofiles/${id}/people`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="zones">
                <h4>
                  <svg width="18" height="18">
                    <IconZones width="18" height="18" />
                  </svg>
                  <span className="ms-1">Zones</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      label="Create Zones"
                      checked={result?.createZones || false}
                      onChange={(e) => onCheckChanged(e, "createZones")}
                    />
                    <Form.Check
                      type="switch"
                      label="Edit Zones"
                      checked={result?.editZones || false}
                      onChange={(e) => onCheckChanged(e, "editZones")}
                    />
                    <Form.Check
                      type="switch"
                      label="Delete Zones"
                      checked={result?.deleteZones || false}
                      onChange={(e) => onCheckChanged(e, "deleteZones")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  label="View all Zones"
                  checked={result?.allZones || false}
                  onChange={(e) => onCheckChanged(e, "allZones")}
                />
                {!result?.allZones && (
                  <SelectionTable
                    filterId=""
                    currentTab={curTab}
                    buttonText="Add a Zone"
                    apiName="zones"
                    title="Zones"
                    columns={assetColumn}
                    id={id}
                    entry={`accessprofiles/${id}/zones`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="rules">
                <h4>
                  <svg width="18" height="18">
                    <IconRules width="18" height="18" />
                  </svg>
                  <span className="ms-1">Rules</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      label="Create Rules"
                      checked={result?.createRules || false}
                      onChange={(e) => onCheckChanged(e, "createRules")}
                    />
                    <Form.Check
                      type="switch"
                      label="Edit Rules"
                      checked={result?.editRules || false}
                      onChange={(e) => onCheckChanged(e, "editRules")}
                    />
                    <Form.Check
                      type="switch"
                      label="Delete Rules"
                      checked={result?.deleteRules || false}
                      onChange={(e) => onCheckChanged(e, "deleteRules")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  label="View all Rules"
                  checked={result?.allRules || false}
                  onChange={(e) => onCheckChanged(e, "allRules")}
                />
                {!result?.allRules && (
                  <SelectionTable
                    filterId=""
                    currentTab={curTab}
                    buttonText="Add a Rule"
                    apiName="rules"
                    title="Rules"
                    columns={ruleColumn}
                    id={id}
                    entry={`accessprofiles/${id}/rules`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="trackers">
                <h4>
                  <svg width="18" height="18">
                    <IconTrackers width="18" height="18" />
                  </svg>
                  <span className="ms-1">Trackers</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Edit Trackers"
                      checked={result?.editTrackers || false}
                      onChange={(e) => onCheckChanged(e, "editTrackers")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="View all Trackers"
                  checked={result?.allTrackers || false}
                  onChange={(e) => onCheckChanged(e, "allTrackers")}
                />
                {!result?.allTrackers && (
                  <SelectionTable
                    filterId=""
                    currentTab={curTab}
                    buttonText="Add a Tracker"
                    apiName="trackers"
                    title="Trackers"
                    columns={trackerColumn}
                    id={id}
                    entry={`accessprofiles/${id}/trackers`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="readers">
                <h4>
                  <svg width="18" height="18">
                    <IconReaders width="18" height="18" />
                  </svg>
                  <span className="ms-1">Readers</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Edit Readers"
                      checked={result?.editReaders || false}
                      onChange={(e) => onCheckChanged(e, "editReaders")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="View all Readers"
                  checked={result?.allReaders || false}
                  onChange={(e) => onCheckChanged(e, "allReaders")}
                />
                {!result?.allReaders && (
                  <SelectionTable
                    filterId=""
                    currentTab={curTab}
                    buttonText="Add a Reader"
                    apiName="readers"
                    title="Readers"
                    columns={trackerColumn}
                    id={id}
                    entry={`accessprofiles/${id}/readers`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="tags">
                <h4>
                  <svg width="18" height="18">
                    <IconTags width="18" height="18" />
                  </svg>
                  <span className="ms-1">Tags</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label="Edit Tags"
                      checked={result?.editTags || false}
                      onChange={(e) => onCheckChanged(e, "editTags")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="View all Tags"
                  checked={result?.allTags || false}
                  onChange={(e) => onCheckChanged(e, "allTags")}
                />
                {!result?.allTags && (
                  <SelectionTable
                    currentTab={curTab}
                    buttonText="Add a Tag"
                    apiName="tags"
                    title="Tags"
                    columns={trackerColumn}
                    id={id}
                    filterId=""
                    entry={`accessprofiles/${id}/tags`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="notes">
                <h4>
                  <svg width="18" height="18">
                    <IconNote width="18" height="18" />
                  </svg>
                  <span className="ms-1">Notes</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      label="Create Notes"
                      checked={result?.createNotes || false}
                      onChange={(e) => onCheckChanged(e, "createNotes")}
                    />
                    <Form.Check
                      type="switch"
                      label="Edit Notes"
                      checked={result?.editNotes || false}
                      onChange={(e) => onCheckChanged(e, "editNotes")}
                    />
                    <Form.Check
                      type="switch"
                      label="Delete Notes"
                      checked={result?.deleteNotes || false}
                      onChange={(e) => onCheckChanged(e, "deleteNotes")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="View Notes"
                  checked={result?.viewNotes || false}
                  onChange={(e) => onCheckChanged(e, "viewNotes")}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="groups">
                <h4>
                  <svg width="18" height="18">
                    <IconContact width="18" height="18" />
                  </svg>
                  <span className="ms-1">Contact Groups</span>
                </h4>
                {!user?.globalCrud && (
                  <>
                    <Form.Check
                      type="switch"
                      label="Create Contacts"
                      checked={result?.createContacts || false}
                      onChange={(e) => onCheckChanged(e, "createContacts")}
                    />
                    <Form.Check
                      type="switch"
                      label="Edit Contacts"
                      checked={result?.editContacts || false}
                      onChange={(e) => onCheckChanged(e, "editContacts")}
                    />
                    <Form.Check
                      type="switch"
                      label="Delete Contacts"
                      checked={result?.deleteContacts || false}
                      onChange={(e) => onCheckChanged(e, "deleteContacts")}
                    />
                  </>
                )}
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label="View all Contacts"
                  checked={result?.allContacts || false}
                  onChange={(e) => onCheckChanged(e, "allContacts")}
                />
                {!result?.allContacts && (
                  <SelectionTable
                    currentTab={curTab}
                    buttonText="Add a Contact Group"
                    apiName="contactgroups"
                    title="Contact Groups"
                    columns={groupColumn}
                    id={id}
                    filterId=""
                    entry={`accessprofiles/${id}/contactgroups`}
                  />
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="tasks">
                <h4>
                  <svg width="18" height="18">
                    <Bookmark width="18" height="18" />
                  </svg>
                  <span className="ms-1">Tasks</span>
                </h4>
                <Form.Check
                  type="switch"
                  label="Schedule Tasks"
                  checked={result?.scheduleTasks || false}
                  onChange={(e) => onCheckChanged(e, "scheduleTasks")}
                />
                <Form.Check
                  type="switch"
                  label="Log Tasks"
                  checked={result?.logTasks || false}
                  onChange={(e) => onCheckChanged(e, "logTasks")}
                />
                <Form.Check
                  type="switch"
                  label="Users can only view tasks assigned to them"
                  checked={!result?.allTasks || false}
                  onChange={(e) =>
                    onCheckChanged(
                      { target: { checked: !e.target.checked } },
                      "allTasks"
                    )
                  }
                />
              </Tab.Pane>
              <Tab.Pane eventKey="tiles">
                <WidgetTabs
                  id={id}
                  onUpdate={() => getDetail()}
                  isProfile
                  apiName={"accessprofiles/widgets"}
                  data={result?.widgets}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="assetsTiles">
                <WidgetTabs
                  id={id}
                  onUpdate={() => getDetail()}
                  isProfile
                  apiName={"accessprofiles/assetwidgets"}
                  data={result?.assetWidgets}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="change_log">
                <ChangeLogTab id={id} type={"accessprofile"} />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </Container>
    </React.Fragment>
  );
};
export default AccessProfilesEdit;
